/*=================================================== SERVICE ==========================================*/

#categori {
    width: 100%;
    background: #fff;
    margin-top: 0px;
    position: relative;
  }
  .service-content {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .service-grid {
    width: 100%;
    margin-top: 0px;
  }
  .service-title {
    padding-top: 0px;
  }
  .service-title h3 {
    font-size: 34px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 2px;
    color: #f05421;
    margin-top: 0px;
    font-weight: 900;
    line-height: 30px;
  }


  .main-title h3{
    font-size: 50px;
  }
  
  .service-title p {
    font-size: 17px;
    letter-spacing: 1px;
    color: #272727;
    padding: 0px;
    line-height: 27px;
    margin: 15px 0px;
    margin-bottom: 50px;
  }
  .about-row {
    margin-top: 0px;
  }
  .service-details {
    margin-bottom: 30px;
    /* box-shadow: 0px 0px 35px 0px #e9e9e9; */
    /* padding: 20px 20px; */
  }
  .service-head {
    /* background-color: #efd236; */
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
  }
  .service-details:hover .service-head img {
    /* opacity: 0.3; */
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  }
  .service-head img {
    width: 30%;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
  }
  .icon{
    width: 1000%;
  }

  .service-bottom {
    padding: 10px 0px;
  }
  .service-bottom h3 {
    margin: 16px 0px;
    font-size: 19px;
    letter-spacing: 1px;
    color: black;
    font-family: "Roboto Slab", serif;
    position: relative;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 21px;
  }
  .service-bottom p {
    color: #3b3b3b;
    font-size: 17px;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .service-icon {
    font-size: 23px;
    color: white;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #efd236;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    background-color: #efd236;
  }
  
  .testimonial {
    width: 100%;
    background-color: white;
    margin-top: 50px;
    position: relative;
    background-size: cover;
  }
  .testimonial:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.91);
    top: 0;
    left: 0;
    z-index: 1;
  }
  .testimonial-content {
    padding-bottom: 40px;
    padding-top: 80px;
  }
  .testimonial-grid {
    width: 100%;
    position: relative;
    z-index: 111;
  }
  .testimonial-title {
    padding-top: 0px;
  }
  .testimonial-title h3 {
    font-size: 34px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 2px;
    color: white;
    margin-top: 0px;
    font-weight: 900;
    line-height: 30px;
  }
  
  .testimonial-details {
    width: 100%;
    margin-top: 50px;
  }
  
  section {
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: 30px; */
    height: auto;
  }

  .underline1 {
    height: 2px;
    width: 100px;
    background-color: #FF8C00;
    margin: auto;
    margin-top: 20px;
    position: relative;
    display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .underline2 {
    height: 10px;
    width: 10px;
    background-color: #FF8C00;
    margin: auto;
    margin-top: -6px;
    transform: rotate(-135deg);
  }

  .no-margin {
    margin-left: 0px;
  }
  
  .quote {
    color: rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 0px;
  }
  
  #fade-quote-carousel.carousel {
    padding-bottom: 0px;
    height: auto;
    position: relative;
  }
  #fade-quote-carousel.carousel .carousel-inner .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;
  }
  #fade-quote-carousel.carousel .carousel-inner .active {
    opacity: 1;
    -webkit-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;
  }
  .carousel-indicators .active {
    opacity: 1;
    -webkit-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;
  }
  #fade-quote-carousel blockquote {
    text-align: center;
    border: none;
  }
  blockquote {
    padding: 34px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
    padding-bottom: 0px;
  }
  .item blockquote p {
    font-size: 17px;
    color: black;
    margin-bottom: 0px;
    line-height: 30px;
    position: relative;
  }
  .item blockquote p span {
    font-size: 22px;
    color: black;
    position: relative;
    line-height: 60px;
  }
  .item {
    height: auto;
  }
  #fade-quote-carousel.carousel .carousel-indicators {
    bottom: -32px;
  }
  #fade-quote-carousel.carousel .carousel-indicators > li {
    background-color: #efd236;
    border: none;
  }
  .fa-4x {
    font-size: 2em;
    color: white;
    height: 50px;
    width: 50px;
    background-color: #efd236;
    line-height: 51px;
    border-radius: 5px;
  }

  @media(max-width:428px){
    .service-head img{
        width: 100px;
    }
}