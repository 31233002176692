/* ------------------------- About start ------------------------ */
.about-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #fff;
  }

  .about-section .hero-image img{
    border-radius: 6px;
  }
  
  .about-section .btn {
    background-color: #483d8b;
    border-color: #483d8b;
  }

  .no-margin {
    margin-left: 0px;
  }

  .underline1 {
    height: 2px;
    width: 100px;
    background-color: #FF8C00;
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .underline2 {
    height: 10px;
    width: 10px;
    background-color: #FF8C00;
   
    margin-top: -6px;
    transform: rotate(-135deg);
  }
  
  .about-section .btn:hover {
    box-shadow: 8px 8px #99bdff;
    transition: 0.3s;
  }
  
  @media (max-width: 1440px) {
    .about-section {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  
  .about-section .hero-content h1 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .about-section .hero-content a {
    margin-top: 1.2rem;
  }
  
  .about-section .hero-content p {
    text-align: justify;
    font-size: 1.1rem;
    margin-top: 1.2rem;
  }
  
  @media (max-width: 768px) {
    .about-section .hero-content h1 {
      font-size: 1.5rem;
    }
  
    .about-section .hero-content p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 426px) {
    .about-section .hero-image img {
      width: 500px !important;
      height: 240px;
      margin: 2rem 0;
      border-radius: 6px;
    }
    .about-section {
      min-height: 70vh;
    }
    .btn-primary {
      justify-content: center;
      align-items: center;
    }
    .about-section .hero-content h1 {
        margin-top: 70px;
      }
  }
  
  /* ------------------------- About end ------------------------ */