/* ------------------------- Organized start ------------------------ */
.event_sponser_area {
    background: #fff;
    background-color: #fff;
    padding-top: 100px;
  }
  
  .event_sponser_area .Organized-by .organized-image .image-iysa {
    max-width: 270px;
    /* margin-top: 200px; */
    margin: 30px;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
  }
  .event_sponser_area .Organized-by .organized-image .image-udayana {
    max-width: 400px;
    /* margin-top: 200px; */
    margin: 40px;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
  }
  
  .event_sponser_area h4 {
    font-weight: bold;
    font-size: 35px;

  }

  .event_sponser_area .Organized-by .organized-image .image-iysa:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  }

  .event_sponser_area .Organized-by .organized-image .image-udayana:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  }
  
  @media (max-width: 991px) {
    .event_sponser_area h4 {
      font-weight: bold;
      font-size: 35px;
      padding-bottom: 10px;
    }
    .event_sponser_area .Organized-by .organized-image .image-iysa {
        padding-top: 50px;
        margin: 10px !important;
      }
      .event_sponser_area .Organized-by .organized-image .image-udayana {
        margin: 10px !important;
        width: 250px;
      }
  }
  /* ------------------------- Organized end ------------------------ */